<template>
  <SedeDesdeHasta
    titulo="Reportes de Inventario - Movimiento General"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    :componenteListado="componenteListado"
  />
</template>
  
<script>
import SedeDesdeHasta from "../components/SedeDesdeHasta.vue";
import ReporteInventarioService from "../services";

export default {
  name: "ReporteInventarioMovimientoGeneralLayout",
  components: {
    SedeDesdeHasta,
  },
  data() {
    return {
      componenteListado: "ListadoMovimientoGeneral",
    };
  },
  methods: {
    servicioGetReporte:
      ReporteInventarioService.getReporteInventarioMovimientoGeneral,
    servicioGetReportePdf:
      ReporteInventarioService.getReporteInventarioMovimientoGeneralPdf,
  },
};
</script>
  